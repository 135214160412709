.App {
  text-align: center;
}

.App-logo {
  height: 7.5vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #50d8af;
  font-size: large;
}

.heel{
  margin-bottom: 1em !important;
}

.alert-box{
  text-align: center;
  width: 95% !important;
  margin: 0 auto;
}

.background-mihandzu {
  height: 100mvh;
  background-color: #2A743C;
}

p{
  color: #000;
}
/* 
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(20deg);
  }
} */
